import React from "react";
import { Instagram, Place, WhatsApp } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";

const Landing = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "background.main",
        py: 2,
      }}
    >
      <Box
        component={Paper}
        sx={{
          width: "90%",
          height: { xs: "85%", md: "90%" },
          maxWidth: "400px",
          gap: 1,
          p: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: 150,
              height: 150,
              borderRadius: "100%",
              bgcolor: "background.main",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              p: 1,
            }}
          >
            <img
              src={require("../../assets/logo.jpg")}
              alt="esmeralda leilões - leilão de artes e antiguidades"
              style={{
                width: "100%",
                maxWidth: 100,
                height: "100%",
                objectFit: "contain",
                alignContent: "center",
                margin: "auto",
                display: "block",
              }}
            />
          </Box>
          <Typography variant="h3" mt={2}>
            Esmeralda Leilões
          </Typography>
          <Typography variant="h6" mb={1} mt={1}>
            Leilão de Artes e Antiguidades
          </Typography>
        </Box>
        <Divider />

        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {/* <Typography variant="h6" mb={1}>Estamos abertos para captação de peças</Typography> */}
          <Typography variant="h6">Próximo Leilão</Typography>
          <Typography variant="h5" color="error">
          07 de Fevereiro, as 18:00h
          </Typography>
          <Typography
            variant="h5"
            color="error"
            my={1}
            sx={{ cursor: "pointer" }}
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send?phone=5521972525983&text=Ol%C3%A1,%20eu%20gostaria%20de%20enviar%20uma%20pe%C3%A7a%20para%20avalia%C3%A7%C3%A3o",
                "_blank"
              )
            }
          >
            Estamos abertos para captação de peças
          </Typography>
        </Box>
        <Divider />

        <Box
          sx={{
            width: "100%",
            gap: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            my: 1,
          }}
        >
          <Button
            variant="contained"
            fullWidth
            href="https://www.marciopinho.com.br/catalogo.asp?Num=49443"
          >
            catálogo
          </Button>
          <Button
            variant="contained"
            fullWidth
            href="https://api.whatsapp.com/send?phone=5521972525983&text=Ol%C3%A1,%20eu%20gostaria%20de%20enviar%20uma%20pe%C3%A7a%20para%20avalia%C3%A7%C3%A3o"
          >
            Envie sua Peça para Avalição
          </Button>
          <Button
            variant="contained"
            fullWidth
            href="https://api.whatsapp.com/send?phone=5521972525983&text=Ol%C3%A1,%20eu%20gostaria%20de%20obter%20maiores%20informa%C3%A7%C3%B5es%20sobre%20a%20venda%20de%20pe%C3%A7as%20em%20leil%C3%A3o%20online."
          >
            Dúvidas sobre leilões
          </Button>
          <Button
            variant="contained"
            fullWidth
            href="https://esmeraldaleiloes.com.br/home"
          >
            Visite Nosso Site
          </Button>
        </Box>
        <Divider />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: 3,
            justifyContent: "center",
            alignIems: "center",
          }}
        >
          <IconButton
            href="https://api.whatsapp.com/send?phone=5521972525983&text=Ol%C3%A1,%20eu%20gostaria%20de%20obter%20maiores%20informa%C3%A7%C3%B5es%20sobre%20a%20venda%20de%20pe%C3%A7as%20em%20leil%C3%A3o%20online."
            aria-label="Tire suas dúvidas em nosso whatsapp."
          >
            <WhatsApp fontSize="large" />
          </IconButton>
          <IconButton
            href="https://www.instagram.com/esmeralda.leiloes/"
            aria-label="Siga nossas redes sociais."
          >
            <Instagram fontSize="large" />
          </IconButton>
          <IconButton
            href="https://goo.gl/maps/roN8K2U8yXzaA4Gv5"
            aria-label="Encontre-nos"
          >
            <Place fontSize="large" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Landing;
